import React from 'react'
import {
  Wrapper,
  Video,
} from '../../../../components'

const VideoWho = ({ url }) => (
  <Wrapper
    flexDirectionD="column"
    displayM="box"
    paddingD="0 0 160px">
    <Wrapper
      widthD="1920px"
      flexDirectionD="column"
    >
      {url && <Video videoInfo={{ url }} />}
    </Wrapper>
  </Wrapper>
)

export default VideoWho
