import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {
  Wrapper, H1, P, Subtitle, H2,
} from '../../../../components'

import IconDowload from '../../../../assets/icons/TAM-icon-download.svg'

const DowonloadWrapper = styled.div`
  display: flex;
  padding: 21px 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #D7D9DD;
  &:last-child {
    border-bottom: 1px solid #D7D9DD;
  }

`

const Downloads = ({
  downloads,
}) => (
  <Wrapper

    flexDirectionD="column"
    displayM="box"
    paddingD="140px 0 0"
    paddingM="80px 25px">
    <Wrapper
      widthD="1920px"
      flexDirectionD="column"
    >
      <Wrapper
        widthD="1440px"
        flexDirectionD="column"
        style={{ overflow: 'hidden' }}
      >
        <Subtitle>Downloadable Forms</Subtitle>
        <Wrapper style={{
          textAlign: 'center',
          marginTop: '4px',
        }} widthD="475px" marginD="12px 0 48px 0">
          <H2
            fontSize="2xll"
            lineHeight="4xll"
            fontSizeD="4xl"
            lineHeightD="6xl"
          >{_.get(downloads, 'section_title')}</H2>
        </Wrapper>
      </Wrapper>
      <Wrapper
        widthD="630px"
        paddingM="40px 0px"
        paddingD="0"
        flexDirectionD="column"

      >
        {
          _.map(_.get(downloads, 'downloads'), (download, idx) => (
            <DowonloadWrapper key={idx}
            >
              <P>{download.file_name}</P>
              <a href={download.link} target="_blank" rel="noreferrer">
                <IconDowload color="#E20613" width="24px"/>
              </a>

            </DowonloadWrapper>
          ))
        }

      </Wrapper>

    </Wrapper>
  </Wrapper>
)

export default Downloads
