import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {
  Wrapper, Subtitle, H2,
} from '../../../../components'
import Carousel from '../carousel/carousel'

const People = ({ teamSection, t, videoUrl }) => (
  <Wrapper
    style={{
      minHeight: '1000px',
    }}
    flexDirectionD="column"
    displayM="box"
    marginD={videoUrl ? '0 0 120px' : '0'}
    paddingM="32px 0 80px"
    paddingD="32px 0 0">
    <Wrapper
      widthD="1920px"
      flexDirectionD="column"
    >
      <Wrapper
        widthD="1440px"
        flexDirectionD="column"
        style={{ overflow: 'hidden' }}
      >
        <Subtitle>{t('who.people')}</Subtitle>
        <Wrapper
          style={{ textAlign: 'center' }}
          widthM="475px"
          widthD="680px"
          paddingM=" 0 25px"
          marginM="0 0 48px 0"
          marginD="12px 0 120px 0">
          <H2
            fontSize="2xll"
            lineHeight="4xll"
            fontSizeD="4xl"
            lineHeightD="6xl"
          >{_.get(teamSection, 'section_title', '')}
          </H2>
        </Wrapper>
        {teamSection && <Carousel teams={_.get(teamSection, 'teams', [])}/>}
      </Wrapper>

    </Wrapper>
  </Wrapper>
)

export default People
