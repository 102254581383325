import React from 'react'
import './timeline-styles.scss'

const Timeline = ({ data }) => {
  // create funtion return radon string between one,two,three,four
  const getRandomString = () => {
    const random = Math.floor(Math.random() * 4) + 1
    switch (random) {
      case 1:
        return 'one'
      case 2:
        return 'two'
      case 3:
        return 'three'
      case 4:
        return 'four'
      default:
        return 'one'
    }
  }

  return (
    <div className='timeline-container'>
      {
        data?.map((item, index) => (
          <div className={index % 2 === 0 ? 'timeline-item timeline-item-left' : 'timeline-item timeline-item-right'} key={index}>
            <span className='timeline-item-marker'></span>
            <span className='timeline-item-line'></span>
            <div className='timeline-item-content'>
              <h3 className='timeline-item-year'>{item.year}</h3>
              <p className='timeline-item-text '>{item.title}</p>
            </div>
            <div className='timeline-item-image-container'>
              <span className='timeline-item-image-line'></span>
              <div className={`timeline-item-image ${getRandomString()}`}>
                <img src={item.image.url} alt={item.image.alt} />
              </div>

            </div>
          </div>
        ))}
      <div className='timeline-bottom'>
        <div className='timeline-bottom-gradient'></div>
        <p>More coming soon!</p>
      </div>
    </div>

  )
}

export default Timeline
