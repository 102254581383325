import _ from 'lodash'
import styled from 'styled-components'
import React from 'react'
import { Wrapper, H2, P } from '../../../../components/page-elements'
import LogoIN from '../../../../assets/icons/TAM-icon-linkedin.svg'

const WrapperIcon = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  a {
    width: 46px;
    height: 46px;
    border: 1px solid #E20613;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`
const InfoPeople = ({ info }) => (
  <Wrapper
    widthD="550px"
    marginD="0 0 0 50px"
    alignItemsD="flex-start"
    flexDirectionD="column"
    paddingD="0 15px"
    style={{
      position: 'absolute',
      zIndex: 10,
      width: '39vw',
      top: 0,
    }}
  >
    {
      _.get(info, 'linkedin') && (
        <WrapperIcon>
          <a
            href={_.get(info, 'linkedin', '')}
            target="_blank"
            rel="noreferrer">
            <LogoIN color="#E20613" width="30" />
          </a>
        </WrapperIcon>
      )
    }
    <H2>{_.get(info, 'name', '')}</H2>
    <P
      style={{
        marginTop: '2px',
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: '13px',
      }}
      color="primary"
      font="manrope"

    >
      {_.get(info, 'position', '')}
    </P>
    <P
      marginD="28px 0 0 0"
      lineHeight="3xl"

    >{_.get(info, 'description', '')}</P>

  </Wrapper>
)

export default InfoPeople
