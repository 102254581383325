import _ from 'lodash'
import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Slider from 'react-slick'
import LogoIN from '../../../../assets/icons/TAM-icon-linkedin.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel-style.scss'
import InfoPeople from '../info-people/info-people'
import { device } from '../../../../theme/device'
import photoPeople from '../../../../assets/images/people/TAM_member_01_m.jpg'
import { useWindowDimensions } from '../../../../helpers'
import { Wrapper, H2, P } from '../../../../components/page-elements'

const WrapperIcon = styled.div`
  width: 100%;
  justify-content: flex-end;
  margin-top: 15px;
  a {
    width: 46px;
    height: 46px;
    border: 1px solid #E20613;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  `
const PeopleInfoWrapper = styled((props) => <Wrapper {...props} />)` 
  @media ${device.tablet} {
    margin-bottom: 48px;
  }

`

const Carousel = ({ teams }) => {
  const [peopleInTeam, setPeopleInTeam] = useState([])
  const [width, setWith] = useState('0')
  // const breakpoints = useBreakpoint()
  const size = useWindowDimensions()
  const widthWindow = _.get(size, 'width', 0)
  const getWidth = (i, idx) => (idx * 100) / i.length
  // const people = _.map(teams, (team) => ({
  //   id: 1 + Math.random() * (1000000 - 1),
  //   ...team,
  // }))
  const [state, setState] = useState([])
  const refCarousel = useRef()

  const onAvanze = (idx) => {
    if (widthWindow > 810) {
      refCarousel.current.slickGoTo(idx)
      setState(peopleInTeam[idx])
    } else {
      refCarousel.current.slickGoTo(idx + 1)
      setState(peopleInTeam[idx + 1])
    }
  }

  useEffect(() => {
    const pe = _.map(teams, (team, idx) => ({
      id: idx,
      ...team,
    }))
    setState(pe.slice(-1)[0])
    setPeopleInTeam(_.reverse(pe))
  }, [teams])

  const settings = {
    dots: false,
    infinite: true,
    className: 'center',
    centerPadding: '-10%',
    speed: 0,
    centerMode: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'slick-dots carousel-dots',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerPadding: '0 10% 0 10%',
          centerMode: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerPadding: '0 10% 0 10%',
          centerMode: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0 10% 0 10%',
          dots: false,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '12%',
          dots: false,
          swipeToSlide: true,
        },
      },
    ],
  }


  return (
    <div className="cards-carousel-container">
      <div className="mask-carousel">
        {widthWindow > 820 && <InfoPeople info={state}/>}

        <Slider
          afterChange={(idx) => {
            setState(peopleInTeam[idx])
            setWith(getWidth(peopleInTeam, idx))
          }}
          {...settings} ref={refCarousel}
        >
          {
            _.map(peopleInTeam, (person, idx) => (
              <div className="slick-box" key={person.id + idx} onClick={() => onAvanze(idx) }>
                <div className="slick-frame" data-number={idx}>

                  {
                    !person.photo.url
                      ? <div style={{
                        display: 'flex',
                        backgroundColor: '#E20613',
                        height: '100%',
                        width: '100%',
                      }}></div>
                      : <img
                        style={{ width: '100%', height: 'auto' }}
                        src={person.photo.url ? person.photo.url : photoPeople}
                        alt={person.photo && person.photo.alt}
                      />
                  }

                </div>
                {
                  widthWindow <= 820 && (
                    <PeopleInfoWrapper
                      widthD="620px"
                      paddingM="0 0 15px 0 "
                      alignItemsM="flex-start"
                      justifyContentM="flex-start"
                    >
                      <H2
                        fontSize="3xl"
                        style={{
                          textAlign: 'left',
                          marginBottom: '4px',
                        }}
                      >{_.get(person, 'name', '')}</H2>
                      <P
                        style={{
                          marginTop: '4px',
                          textTransform: 'uppercase',
                          fontWeight: 600,
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        color="primary"
                        font="manrope"

                      >
                        {_.get(person, 'position', '')}
                      </P>
                      <P
                        marginM="16px 0 0 0"
                        lineHeight="3xl"
                        style={{
                          textAlign: 'left',
                          margin: '16px 0 0 0',
                        }}

                      >{_.get(person, 'description', '')}</P>
                      {_.get(person, 'linkedin') && <WrapperIcon>
                        <a
                          href={_.get(person, 'linkedin')}
                          target="_blank"
                          rel="noreferrer">
                          <LogoIN color="#E20613" width="30" />
                        </a>
                      </WrapperIcon>
                      }
                    </PeopleInfoWrapper>
                  )
                }
              </div>
            ))}
        </Slider>
        <div className="people-mobile-line">
          <span style={{
            left: `${width}%`,
            width: `${100 / peopleInTeam.length}%`,
          }}> </span>
        </div>
      </div>
    </div>
  )
}

export default Carousel
