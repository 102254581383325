import React, { useEffect } from 'react'
import _ from 'lodash'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { useWindowDimensions } from '../../helpers'
import {
  Wrapper,
  FullImage,
  Spinner,
  BlockNumbers,
  Seo,
} from '../../components'
import {
  MapWho,
  VideoWho,
  People,
  History,
  Downloads,
  HistoryMobile,
} from './components'
import image from '../../assets/images/TAM_who_we_are_banner_1920.jpg'
import Timeline from './components/timeline'

const WhoWeAreView = ({
  data,
  loading,
  t,
  statistics,
  location,
}) => {
  const history = _.get(data, 'acf.milestone_section')
  const teamSection = _.get(data, 'acf.team_section')
  const videoUrl = _.get(data, 'acf.video_section.video_link')
  const downloads = _.get(data, 'acf.download_section')
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const breakpoints = useBreakpoint()

  useEffect(() => {
    if (!loading && location.hash) {
      scrollTo(`${location.hash}`, 'nearest')
    }
  }, [location, loading])

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <React.Fragment>
      <Seo
        title={_.get(data, 'yoast_head_json.title')}
        description={_.get(data, 'yoast_head_json.description')}
        schema={_.get(data, 'yoast_head_json.schema')}
        type={_.get(data, 'yoast_head_json.og_type', {})}
      />
      <Wrapper flexDirectionD="column">
        <div id="team"></div>
        <People teamSection={teamSection} t={t} videoUrl={videoUrl} />
        {videoUrl && <VideoWho url={videoUrl} />}
        <div id="mission"></div>
        <BlockNumbers
          breakpoints={breakpoints}
          widthBackground
          statistics={statistics}
          data={_.get(data, 'acf.misson_section')}
          title={t('what.title')}
          t={t} />
        <div id="network"></div>
        <MapWho t={t} />
        <div id="milestones" style={{ minHeight: '600px', width: '100%' }}>
          <Timeline data={history} />
          {/* { (width < 1024 && history) && <HistoryMobile data={history} />}
          { (width >= 1024 && history) && <History data={history} /> } */}
        </div>
        <FullImage image={image} />
        <div id="downloadable"></div>
        <Downloads downloads={downloads} />
      </Wrapper>
    </React.Fragment>
  )
}

export default WhoWeAreView
