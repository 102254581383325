import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import './history-style.scss'
import {
  useSpring, animated,
} from 'react-spring'

import IconAirplane from '../../../../assets/icons/TAM-icon-airplane.svg'
/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-vertical-carousel-component-in-react
 */

function Circle({ toggle }) {
  const animatedStyle = useSpring({
    from: { strokeDashoffset: 0 },
    to: {
      strokeDashoffset: toggle ? 1069 : 0,
    },
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
      clamp: false,
      precision: 0.01,
      velocity: 1,
      duration: 200,
    },

  })
  return (
    <animated.circle
      style={animatedStyle}
      className="circle-history"
      cx="162"
      cy="162"
      r="161"
      stroke="#E20613"
      strokeWidth="2"
      fillOpacity="0"
      strokeDasharray="1069"
    />
  )
}

const VerticalCarousel = ({ data }) => {
  const [isToggled, setToggle] = useState(false)
  // const fadeUp = useSpring({
  //   strokeDasharray: 2170,
  //   strokeDashoffset: isToggled ? 2170 : 0,
  //   delay: isToggled ? 0 : 100,
  //   config: {
  //     friction: isToggled ? 100 : 50,
  //   },
  // })

  const [activeIndex, setActiveIndex] = useState(0)

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(data.length / 2)

  // Usd to determine the height/spacing of each item
  const itemHeight = 140

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2

  const determinePlacement = (itemIndex) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0
    // setToggle(false)
    if (itemIndex >= halfwayIndex) {
      // setToggle(true)
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight
      }
      return -(data.length + activeIndex - itemIndex) * itemHeight
    }

    if (itemIndex > activeIndex) {
      // setToggle(true)
      return (itemIndex - activeIndex) * itemHeight
    }

    if (itemIndex < activeIndex) {
      // setToggle(true)
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data.length - (activeIndex - itemIndex)) * itemHeight
      }
      return -(activeIndex - itemIndex) * itemHeight
    }
  }

  useEffect(() => {
    setToggle(true)
    const timer = setTimeout(() => {
      setToggle(false)
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [activeIndex])
  return (
    <div className="container-history">
      <section className="outer-container">
        <div className="content" >

          <div className="content-image">
            <img
              src={data[activeIndex].image.url}
              alt={data[activeIndex].image.alt}
            />
          </div>
          <div className="content-circle">
            <svg className="circle-box" height="330" width="330" style={{ transform: 'rotate(-90deg)' }}>
              <circle
                cx="162"
                cy="162"
                r="161"
                stroke="#D7D9DD"
                strokeWidth="1"
                fillOpacity="0"
              />
              <Circle toggle={isToggled} />
            </svg>
            <IconAirplane fill={'#E20613'} width="30" />
            <p className="content-circle-text">{data[activeIndex].title}</p>
            <p className="content-circle-subtitle">{data[activeIndex].subtitle}</p>
          </div>

        </div>
        <div className="carousel-wrapper">

          <div className="carousel">
            <div className="slides">
              <div className="carousel-inner">
                {data.map((item, i) => (
                  <button
                    type="button"
                    onClick={() => setActiveIndex(i)}
                    className={cn('carousel-item', {
                      active: activeIndex === i,
                      visible:
                        Math.abs(determinePlacement(i)) <= visibleStyleThreshold,
                    })}
                    key={`${item.title}-${i}`}
                    style={{
                      transform: `translateY(${determinePlacement(i)}px)`,
                    }}
                  >
                    <p>{item.year}</p>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  )
}

export default VerticalCarousel
