import _ from 'lodash'
import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import {
  Map, Wrapper, Subtitle, H2, ButtonMore,
} from '../../../../components'
import { useWindowDimensions } from '../../../../helpers'
import { useOffices } from '../../../../hooks/use-offices'
import { usePartners } from '../../../../hooks/use-partners'

const MapWho = ({ t }) => {
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const breakpoints = useBreakpoint()
  const { offices } = useOffices()
  const { partners } = usePartners()
  return (
    <Wrapper
      flexDirectionD="column"
      displayM="box"
      style={{
        minHeight: '800px',
      }}
      // heightD="600px"
      paddingD="80px 25px">
      <Wrapper
        widthD="1920px"
        flexDirectionD="column"
      >
        {width <= 820 && <Wrapper
          widthD="1440px"
          flexDirectionD="column"
          marginM="50px 0 0 0"
          style={{ overflow: 'hidden' }}
        >
          <Subtitle>{t('who.map.title')}</Subtitle>
          <Wrapper
            style={{ textAlign: 'center' }}
            widthM="475px"
            widthD="680px"
            paddingM=" 0 25px"
            marginM="0"
            marginD="12px 0 48px 0">
            <H2
              fontSize="2xll"
              lineHeight="4xll"
              fontSizeD="4xl"
              lineHeightD="6xl"
            >
              {t('who.map.info', { offices: offices.length, branchs: partners.length })}
            </H2>
          </Wrapper>
        </Wrapper>
        }
        <Map t={t} isHome />

      </Wrapper>
      {
        breakpoints.xs && (
          <Wrapper
            marginM="40px 0 120px">
            <ButtonMore
              right
              toright={'true'}
              link="/connect-with-us/"
            >{t('who.map.view')}
            </ButtonMore >
          </Wrapper>
        )
      }
    </Wrapper>

  )
}

export default MapWho
