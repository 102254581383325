import React from 'react'
import { useCarousel } from '../../../../hooks/use-carousel.ts'

const CarouselYears = ({ slides, interval = 5000, onClick }) => {
  const [active, setActive, handlers, style] = useCarousel(slides.length, interval)
  const setItemActive = (id, idx) => {
    setActive(idx)
    onClick(id)
  }
  return (
    slides.length > 0 && (
      <div className="we-carousel">
        <ol className="we-carousel-indicators">
          {slides.map((item, i) => (
            <li
              onClick={() => setActive(i)}
              key={i}
              className={`${active === i ? 'active' : ''}`}
            />
          ))}
        </ol>
        <div className="we-carousel-content" {...handlers} style={style}>
          <div className="we-carousel-item">{slides[slides.length - 1].year}</div>
          {slides.map((slide, i) => (
            <div className="we-carousel-item" key={slide.id} onClick={() => setItemActive(slide.id, i)}>
              <p className={`${active === i ? 'active' : ''}`}>{slide.year} {i}</p>
            </div>
          ))}
          <div className="carousel-item">{slides[0].year}</div>
        </div>
      </div>
    )
  )
}

export default CarouselYears
