import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import WhoWeAreView from './who-we-are-view'
import { makeRequest, changelanguages } from '../../helpers'

const WhoWeAreController = ({ location }) => {
  const [data, setData] = useState([])
  const [homeData, setHomeData] = useState([])
  const [loading, setLoading] = useState(true)
  const [statistics, setStatistics] = useState([{
    key: null,
    title: null,
    value: null,
  }])
  const { t, i18n } = useTranslation()

  const getHomeData = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: {
        lang: changelanguages(i18n.language),
        slug: 'home',
      },
    }).then((resp) => {
      setHomeData(resp.data[0])
      setLoading(false)
    })
  }, [])

  const getWhoWeAre = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: {
        lang: changelanguages(i18n.language),
        slug: 'who-we-are',
      },
    }).then((resp) => {
      setData(resp.data[0])
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const st = _.get(homeData, 'acf.what_we_do.statistics')
    const objs = _.reduce(st, (result, value, key) => {
      (result).push({
        key,
        title: t(`home.statistics.${key}`),
        value,
      })
      return result
    }, [])

    setStatistics(objs)
  }, [homeData])

  useEffect(() => {
    getWhoWeAre()
    getHomeData()
  }, [i18n.language])

  const viewProps = {
    loading,
    data,
    homeData,
    statistics,
    location,
    t,
  }
  return (
    <WhoWeAreView {...viewProps} />
  )
}

export default WhoWeAreController
